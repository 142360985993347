import {
  FixedPrice,
  ProductDetailsFragment_ProductFragment,
} from '@generated/graphql'
import { useBuyButtonPDP } from 'src/sdk/cart/useBuyButtonPDP'
import { PriceInfo } from './typings'
import { getFixedPricesToShow } from './utilities'

type BuyButtonProps = {
  fixedPrices: Array<FixedPrice | null> | null | undefined
  priceInfo: PriceInfo
  product: ProductDetailsFragment_ProductFragment
  quantity: number
  handleOpenCloseAddToCart: (x: boolean) => void
  isProgressiveDiscount: boolean
}

function BuyButtonPDP({
  fixedPrices,
  priceInfo,
  product,
  quantity,
  handleOpenCloseAddToCart,
  isProgressiveDiscount,
}: BuyButtonProps) {
  const { discount: fixedDiscount } = getFixedPricesToShow(
    fixedPrices,
    quantity,
    priceInfo.verifiedUnitMultiplier,
    priceInfo.verifiedListPriceToShow
  )

  const buyProps = useBuyButtonPDP({
    item: {
      id: product.id,
      price: product?.offers?.offers[0]?.price,
      listPrice: product?.offers?.offers[0]?.listPrice,
      seller: product?.offers?.offers[0]?.seller,
      quantity: quantity,
      itemOffered: {
        sku: product.sku,
        name: product.name,
        breadcrumbList: product.breadcrumbList,
        gtin: product.gtin,
        image: product.image,
        brand: product.brand,
        isVariantOf: product.isVariantOf,
        additionalProperty: product.additionalProperty,
        productSpecifications: product.productSpecifications,
      },
    },
    boxType: isProgressiveDiscount ? 'progressive buy box' : 'buy box',
    boxDiscount: fixedDiscount,
    recurringOrder: false,
  })

  const handleAddToCartClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    buyProps.onClick(e)
    handleOpenCloseAddToCart(true)
  }

  return (
    <button
      className="btn btn-primary w-full sticky-target-JS cursor-pointer"
      data-modal="product-added-to-cart"
      data-tab="prodotto-scelto-in-cart"
      data-open
      onClick={(e) => handleAddToCartClick(e)}
    >
      Aggiungi al carrello
    </button>
  )
}

export default BuyButtonPDP
